import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RolesService } from '../roles/roles.service';

@Injectable({
  providedIn: 'root'
})
export class AprobarCreditosGuard implements CanActivate {

  rid: string;
  roloptions: any[] = [];
  p = false;

  constructor(
    public router: Router
  ){}

  canActivate() {
    this.roloptions = JSON.parse(localStorage.getItem('data'));
    if ( this.roloptions.includes(btoa('aprobar creditos')) ) {
      return true
    } else {
      console.log("NO PUEDE APROBAR CREDITOS")
      this.router.navigate(['/home']);
      return false;
    }
  }
  
}
