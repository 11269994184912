import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuardGuard, OportunidadesGuard, RegEmpGuard, ModRegEmpPcGuard, UsuariosPcGuard } from '../services/service.index';
import { LapruebaComponent } from './laprueba/laprueba.component';
import { MaininversionistaComponent } from './maininversionista/maininversionista.component';
import { OportunidadesComponent } from './oportunidades/oportunidades.component';
import { OportunidadComponent } from './oportunidades/oportunidad.component';
import { RegistroComponent } from './registro/registro.component';
import { RegistrousuarioComponent } from './registro/registrousuario/registrousuario.component';
import { RegistrodocumentosComponent } from './registro/registrodocumentos/registrodocumentos/registrodocumentos.component';
import { MainclienteComponent } from './maincliente/maincliente.component';
import { SolicitudcreditoComponent } from './maincliente/solicitudcredito.component';
import { RegistroempleadoComponent } from './registroempleado/registroempleado.component';
import { SignatoryesComponent } from './signatoryes/signatoryes.component';
import { SignatoryesDetComponent } from './signatoryes/signatoryesdet.component';
import { ModregempComponent } from './modregemp/modregemp.component';
import { ModregempdetComponent } from './modregemp/modregempdet.component';
import { EditarUsuarioComponent } from '../controlpanel/catalogos/usuarios/editarusuario.component';

const pagesRoutes: Routes = [
        { path: 'registro',          component: RegistroComponent },
        { path: 'inversionista',          component: MaininversionistaComponent },
        { path: 'oportunidades',          component: OportunidadesComponent },
        { path: 'oportunidades/oportunidad/:id',          component: OportunidadComponent },
        { path: 'registrousuario',          component: RegistrousuarioComponent },
        { path: 'registrodocumentos',          component: RegistrodocumentosComponent },
        { path: 'maincliente/:id',          component: MainclienteComponent },
        { path: 'maincliente/solicitudcredito/:id',          component: SolicitudcreditoComponent },
        { path: 'registroemp', component: RegistroempleadoComponent, canActivate: [RegEmpGuard] },
       // { path: 'signatoryes', component: SignatoryesComponent },
        { path: 'signatoryes/detcred/:id', component: SignatoryesDetComponent },
        { path: 'modregemp', component: ModregempComponent, canActivate: [ModRegEmpPcGuard] },
        { path: 'modregemp/det/:id', component: ModregempdetComponent, canActivate: [ModRegEmpPcGuard] },
        { path: 'editarusuario/:id/:usuario',          component: EditarUsuarioComponent },
        { path: '**', redirectTo: '/home', pathMatch: 'full' }
];


export const PAGES_ROUTES = RouterModule.forChild( pagesRoutes );