import { Component, OnInit } from '@angular/core';
import { ListasService, RolesService, UsuarioService } from '../../../services/service.index';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import swal2 from 'sweetalert2';
import { AwsService } from 'src/app/services/service.index';

@Component({
  selector: 'app-editarusuario',
  templateUrl: './editarusuario.component.html',
  styles: []
})
export class EditarUsuarioComponent implements OnInit {

  forma: FormGroup;
  formaRc: FormGroup;
  resp: any;
  listaEstatus = [];
  genero: any[];
  showModal: boolean;
  idu = '';
  roles: any[];
  empresa = false;
  cliente = false;
  companies = [];
  firmantes: any[];
  usuario = false;
  data;

  constructor( public _usuarioService: UsuarioService,
               public http: HttpClient,
               private route: ActivatedRoute,
               private _roles: RolesService,
               public router: Router,
               private listasService: ListasService,
               private awsService: AwsService ) { }

  ngOnInit() {
    swal2.showLoading();
    const id = this.route.snapshot.paramMap.get('id');
    this.idu = this.route.snapshot.paramMap.get('id');
    this.data = JSON.parse(localStorage.getItem('data'));
    let usuario = this.route.snapshot.paramMap.get('usuario');
    if(usuario == 'usuario'){
      this.usuario = true;
    }
    this.forma = new FormGroup({
      nombre: new FormControl( null),
      correo: new FormControl( null),
      password: new FormControl( null),
      puesto: new FormControl( null ),
      genero: new FormControl( null ),
      estatus: new FormControl( null ),
    });

    this.formaRc = new FormGroup({
      passwordRc: new FormControl( null , Validators.required ),
      password2Rc: new FormControl( null , Validators.required ),
    }, { validators: this.sonIguales( 'passwordRc', 'password2Rc' )  });
    this._usuarioService.getusuario( id ).subscribe( resp => {this.resp = resp; },
      err => {
        console.clear();
        console.log("ocurrio un error");
        // console.log(err); 
    }
      );
    this._usuarioService.getUserGender().subscribe( resp => this.genero = resp );
    this.listasService.getLists().subscribe(resp => {
      resp['data'].forEach(list => {
        if(list['attributes'].domain === 'USER_STATUS'){
          this.listaEstatus.push(list['attributes']);
        }
      });
    })
    // this._usuarioService.getUserStatus().subscribe( resp => {this.listaEstatus = resp; console.log(this.listaEstatus)} );
    this._roles.getRoles().subscribe(r => {this.roles = r;});
    this._usuarioService.getUsuarios().subscribe(f => {this.firmantes = f.filter(r => r.company_id != null);
    })
    this.awsService.getCompanyes().subscribe(resp => {
      this.companies = resp;
    })

    swal2.close();
  }

  selRol(){
    var rol = (<HTMLInputElement>document.getElementById('rol')).value;
    var name = this.roles.find(r => r.id == rol).name;
    if(name == 'Empresa'){
      this.empresa = true;
    }else{
      this.empresa = false;
    }
    if(name == 'Clientes'){
      this.cliente = true;
    }else{
      this.cliente = false;
    }
  }

show() {
    this.showModal = true; // Show-Hide Modal Check
  }
hide() {
    this.showModal = false;
  }

sonIguales( campo1: string, campo2: string ) {

    return ( group: FormGroup ) => {

      let pass1 = group.controls[campo1].value;
      let pass2 = group.controls[campo2].value;

      if ( pass1 === pass2 ) {
        return null;
      }

      return {
        sonIguales: true
      };

    };

  }

  guardarCambios() {
    swal2.showLoading();
    // Obtener el elemento por el id
    const id = this.route.snapshot.paramMap.get('id');
    const genero: any = document.getElementById('genero');
    const estatus: any = document.getElementById('estatus');
    const nombre: any = document.getElementById('nomb');
    const email: any = document.getElementById('mail');
    const puesto: any = document.getElementById('pues');
    let rol = '';
    if(!this.usuario){
      rol = (<HTMLInputElement>document.getElementById('rol')).value;

    }
// Obtener el valor de la opción seleccionada
    const valorGenero = genero.options[genero.selectedIndex].value;
    const valorEstatus = estatus.options[estatus.selectedIndex].value;
    const valorNombre = nombre.value;
    const valorEmail = email.value;
    const valorPuesto = puesto.value;

    let company = '';
    try{
      company = (<HTMLInputElement>document.getElementById('company')).value;
    }catch{}
    let firmante = '';
    try{
      firmante = (<HTMLInputElement>document.getElementById('firmante')).value;
    }catch{}

// Obtener el texto que muestra la opción seleccionada
//    let valorSeleccionado2 = this.genero.options[this.genero.selectedIndex].text;

    if ( this.forma.invalid ) {
      return;
    }


    const params = {
      token: '',
      secret_key: '',
      name: valorNombre,
      email: valorEmail,
      job: valorPuesto,
      gender: valorGenero,
      status: valorEstatus,
      role_id: rol,
      company_id: company,
      company_signatory: firmante
    }

    this._usuarioService.actualizaUsuario(id, params).subscribe( () => {
          swal2.close();
          swal2.fire({
            title: 'El usuario',
            text: 'fue Modificado con exito',
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {

            if ( res.value ) {
              this.router.navigate(['/panelcontrol/usuarios']);
            }

          } );

        }, (err) => {
          swal2.close();
          console.log(err);
          console.clear();
          swal2.fire({
            title: 'Ocurrio un error',
            text: '',
            icon: 'error',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {
            if ( res.value ) {
              this.ngOnInit();
            }
          } );
         } );
  }

  resetpassword() {

    if ( this.formaRc.invalid ) {
      return;
    }

    const params = {
      token: '',
      secret_key: '',
      password: this.formaRc.value.passwordRc
    };

    this._usuarioService.actualizaUsuarioRcPc( this.idu, params )
              .subscribe( resp => {// this.router.navigate(['/login']);
              swal2.fire(
                'Cambio de contraseña',
                'Exitosa',
                'success'
             );
              this.hide();
            }, (err) => { console.log(err);
                          console.clear();
                          swal2.fire(
                                        'Error al cambiar contraseña',
                                        'Error',
                                        'error'
                                     );
                          this.hide();
                                  }
            );


  }

}
