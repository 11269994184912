// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  URL_SERVICIOS: 'https://nbtest.factorgfcleasing.com/api/v1',
  SECRET_KEY: '99adb154213e4793ec58a64ffc49f4f2',
  URL_BASE_TUIDENTIDAD: 'https://dev.tuidentidad.com/api/',
  AWS: {
    accessKeyId: "AKIAWRCSQFXTXKQ57W5G",
    secretAccessKey: "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",
    region: 'us-east-1',
    folder: 'Test/',
    bucketNomina: 'nominabucket'
  },
  firebase: {

    apiKey: "AIzaSyDpDtnejjtDCPH-vqrleaE64KQpXK8eAL4",
  
    authDomain: "testnomina-f377d.firebaseapp.com",
  
    projectId: "testnomina-f377d",
  
    storageBucket: "testnomina-f377d.appspot.com",
  
    messagingSenderId: "451147653180",
  
    appId: "1:451147653180:web:1033c0ccba52502cdc2339",
  
    measurementId: "G-KGQX6B8XMS"
  
  }
  
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
