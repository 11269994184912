import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AwsService, ContribuyentesService, ListasService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styles: [
  ]
})

export class EmpresasComponent implements OnInit {
  legal_entity = new FormGroup({
    business_name: new FormControl(),
    rfc: new FormControl(),
    fiscal_regime: new FormControl(),
    rug: new FormControl(),
    main_activity: new FormControl(),
    phone: new FormControl(),
    mobile: new FormControl(),
    email: new FormControl('', [Validators.required, Validators.email]),
    business_email: new FormControl('', [Validators.required, Validators.email]),
    fiel: new FormControl(),
  })

  person = new FormGroup({
    first_name: new FormControl(), 
    last_name: new FormControl(), 
    second_last_name: new FormControl(), 
    gender: new FormControl(),    
    rfc: new FormControl(), 
    fiel: new FormControl(), 
    curp: new FormControl(), 
    imss: new FormControl(),   
    id_type: new FormControl(), 
    identification: new FormControl(), 
    nationality: new FormControl(), 
    birthdate: new FormControl(),  
    birth_country: new FormControl(), 
    birthplace: new FormControl(), 
    martial_status: new FormControl(), 
    minior_dependents: new FormControl(), 
    senior_dependents: new FormControl(),
    housing_type: new FormControl(), 
    phone: new FormControl(),
    mobile: new FormControl(), 
    email: new FormControl('', [Validators.required, Validators.email]), 
    extra1: new FormControl(), 
    extra2: new FormControl(),
    extra3: new FormControl(),
    martial_regime: new FormControl(), 
    fiscal_regime: new FormControl(), 
  })

  company = new FormGroup({
    business_name: new FormControl(),
    start_date: new FormControl(),
    credit_limit: new FormControl(100000, []),
    credit_available: new FormControl(100000, []),
    sector: new FormControl(),
    subsector: new FormControl(),
    company_rate: new FormControl(),
  })
  
  contributor_address = new FormGroup({
    state_id: new FormControl(),
    municipality_id: new FormControl(),
    address_type: new FormControl(),
    suburb_type: new FormControl(),
    street: new FormControl(),
    external_number: new FormControl(),
    apartment_number: new FormControl(),
    postal_code: new FormControl(),
    suburb: new FormControl(),
    address_reference: new FormControl(),
  })
  
  
  contributor = new FormGroup({
    contributor_type: new FormControl(),
    bank: new FormControl('default', []),
    account_number: new FormControl(123, []),
    clabe: new FormControl(123, []),
    extra1 :new FormControl(123, []),
  })

  company_segments = new FormGroup({
      key: new FormControl(),
      company_rate: new FormControl(),
      credit_limit: new FormControl(),
      max_period: new FormControl(),
      commission: new FormControl(),
      currency: new FormControl('MXN', []),
      extra1: new FormControl(),
    })

  lists = [];
  fiscal_regime = [];
  countries = [];
  states = [];
  municipies = [];
  colonias = [];
  capturaCp = false;
  address_type = [];
  suburb_type = [];
  segmentos = [];
  martial_status = [];
  fiscal = '';
  houseType = [];
  contributor_id = '';
  cols = [
    {field: 'key', header: 'Identificador'},
    {field: 'company_rate', header: 'Tasa'},
    {field: 'credit_limit', header: 'Límite de crédito'},
    {field: 'max_period', header: 'Plazo máximo'},
    {field: 'commission', header: 'Comisión'},
    {field: 'currency', header: 'Moneda'},
    {field: 'extra1', header: 'Día de pago'},
  ]
  cId = false;
  seniority = [];
  company_rate = [];
  weekday = [];

  legal_entity_group = this._formBuilder.group({});
  company_group = this._formBuilder.group({});
  contributor_address_group = this._formBuilder.group({});
  company_segments_group = this._formBuilder.group({});
    
    constructor(private listasService: ListasService,
                private aws: AwsService,
                private contribuyentesService: ContribuyentesService,
                public router: Router,
                private route: ActivatedRoute,
                private _formBuilder: FormBuilder) { }
    
    ngOnInit(): void {
      this.listasService.getLists().subscribe(lists => {
        lists['data'].forEach(list => {
          this.lists.push(list.attributes);
        });
      this.fiscal_regime = this.lists.filter(p => p.domain === 'CONTRIBUTOR_FISCAL_REGIME');
      this.address_type = this.lists.filter(p => p.domain === 'CONTRIBUTOR_ADDRESS_ADDRESS_TYPE');
      this.suburb_type = this.lists.filter(p => p.domain === 'CONTRIBUTOR_ADDRESS_SUBURB_TYPE');
      this.martial_status = this.lists.filter(p => p.domain === 'PERSON_MARTIAL_STATUS');
      this.houseType = this.lists.filter(p => p.domain === 'HOUSE_TYPE');
      this.seniority = this.lists.filter(p => p.domain === 'SENIORITY');
      this.company_rate = this.lists.filter(p => p.domain === 'COMPANY_RATE');
      this.weekday = this.lists.filter(p => p.domain === 'WEEKDAY');
      })
      this.aws.getCountriesD().subscribe( resp => { this.countries = resp; } );
      if(this.route.snapshot.paramMap.get('contribuyente')){
        this.cId = true
        this.contributor_id = this.route.snapshot.paramMap.get('contribuyente');
        this.contribuyentesService.getContributorData(this.contributor_id).subscribe(resp => {
          this.buscaEdo(this.countries.find(c => c.name == 'Mexico').id);
          var respuesta = resp.data;
          console.log(respuesta)
          this.company.patchValue(respuesta.relations.companies[0].attributes);
          respuesta.relations.company_segments.forEach(c => {
            this.segmentos.push(c.attributes);
          });
          this.contributor_address.patchValue(respuesta.relations.contributor_addresses[0].attributes);
          this.buscaMun(this.contributor_address.value.state_id);
          this.getColonias(this.contributor_address.value.postal_code);
          this.contributor.patchValue(respuesta.relations.contributors[0].attributes);
          this.fiscal = this.contributor.value.contributor_type;
          if(respuesta.type == 'legal_entities'){
            this.legal_entity.patchValue(respuesta.attributes);
          }else if(respuesta.type == 'people'){
            this.person.patchValue(respuesta.attributes);
          }
        })
      }
    }

    credito(){
      console.log('entrada', this.cId,this.company.value)
      if(!this.cId){
        this.company.value.credit_available = this.company.value.credit_limit;
      }
    }

    evaluarRFC(){
      console.log(this.legal_entity.value)
      if(this.legal_entity.value.rfc.length == 13){
        console.log('PF')
      }else if(this.legal_entity.value.rfc.length == 12){
        console.log('PM')
      }else{
        swal2.fire('Atención', 'RFC incorrecto', 'info');
        return;
      }
    }

    /* valoresRepetidos(){
      this.company.value.business_name = this.legal_entity.value.business_name;
      console.log(this.legal_entity.value, this.company.value)
    } */

    buscaEdo(id) {
      this.aws.getStatesId(id).subscribe( r => {this.states = r;} )
     }
   
     buscaMun(id) {
      this.aws.getMunicipios(id).subscribe( r => {this.municipies = r;} )
     }
     getColonias(event) {
      this.aws.getColoniasxcp(event).subscribe(r => {
         this.colonias = r;
         this.capturaCp = false;
         if(this.colonias.length == 0){
           this.capturaCp = true;
         }
       })
     }

     agregarSegmento(){
      this.segmentos.push(this.company_segments.value);
     }

     borrar(i){
      this.segmentos.splice(i, 1);
      }
    
  send(){
    this.contributor.value.contributor_type = this.legal_entity.value.fiscal_regime;
    var params = {};
    this.contributor.value.contributor_type = this.fiscal;
    params['contributor'] = this.contributor.value;
    params['contributor_address'] = this.contributor_address.value;
    params['company'] = this.company.value;
    params['company_segments'] = this.segmentos;
    if(this.fiscal == 'PM'){
      this.legal_entity.value.fiscal_regime = this.fiscal;
      params['legal_entity'] = this.legal_entity.value;
    }else if(this.fiscal == 'PF'){
      this.person.value.fiscal_regime = this.fiscal
      params['person'] = this.person.value;
    }
    
    console.log(params);
    if(this.contributor_id){
      console.log('patch');
      params['contributor_id'] = this.contributor_id;
      this.contribuyentesService.patchCompanyRegistration(params).subscribe(resp => {
        console.log(resp);
        swal2.fire('Éxito', 'Empresa dada de alta correctamente', 'success');
        this.router.navigate(['/panelcontrol/empresas']);
      }, err => {
        console.log(err);
        swal2.fire('Atención', 'Algo salió mal', 'info');
      })
    }else{
      console.log('post');
      this.contribuyentesService.companyRegistration(params).subscribe(resp => {
        console.log(resp);
        swal2.fire('Éxito', 'Empresa dada de alta correctamente', 'success');
        this.router.navigate(['/panelcontrol/empresas']);
      }, err => {
        console.log(err);
        var error = '';
        try{
          Object.keys(err.error.error).forEach(e => {
            error += e + ' ' + err.error.error[e][0] + '<br>';
          });
          swal2.fire('Atención', error, 'info');
        }catch{
          swal2.fire('Atención', 'Algo salió mal', 'info');
        }
      })
    }
  }
  
}
