import { Component, OnInit } from '@angular/core';
import { ContribuyentesService, UsuarioService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-lista-empresas',
  templateUrl: './lista-empresas.component.html',
  styles: [
  ]
})
export class ListaEmpresasComponent implements OnInit {
  contributors: any[] = [];
  cols: any[];
  nombre = '';
  constructor( private usuariosservice: UsuarioService,
                private contribuyentesService: ContribuyentesService ) {
  }

  ngOnInit(): void {
    
    this.contribuyentesService.getContributorsMain().subscribe( resp => { 
      this.contributors = resp;
    } );
    this.cols = [

      { field: 'nombre', header: 'nombre' },
      { field: 'rfc_contribuyente', header: 'rfc_contribuyente' },
      { field: 'correo', header: 'correo' },
  ];

  }

  borrarCompany(id){
    this.contribuyentesService.deleteCompany(id).subscribe(r => {
      console.log(r);
      swal2.fire('Éxito', 'Usuario eliminado correctamente', 'success');
      this.ngOnInit();
    }, err => {
      console.log(err);
      try{
        swal2.fire('Atención', err.error.error, 'info')
      }catch{
        swal2.fire('Atención', 'Algo salió mal', 'info')
      }
    })
  }

   
  

}

