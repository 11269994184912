import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';


@Injectable()
export class DocumentosService {

  token: string;
  idUsuario: string;

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    this.cargarStorage();
  }

  cargarStorage() {

    if ( localStorage.getItem('tkn')) {
      this.token = localStorage.getItem('tkn');
    } else {
      this.token = '';
    }

  }

// SERVICIOS EXTERNOS
  crearExtService( params ) {
    params.secret_key = environment.SECRET_KEY;
    const url = `${ environment.URL_SERVICIOS }/ext_services?token=${this.token}`; //  ?option[name]=${ name }&option[description]=${ desc }&option[group]=${ group }&option[url]=${ urlo }&token=1&secret_key=${ environment.SECRET_KEY };

    return this.http.post( url, params ).pipe(
              map( (resp: any) => {
                return resp;
              }));
  }

  getExtServices() {

    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY
    }
    const url = `${ environment.URL_SERVICIOS }/ext_services?`;

    return this.http.get( url, { params } ).pipe(
    map( (resp: any) => {return this.crearArregloext_services(resp);
    }));

    }
  crearArregloext_services( optionsObj: any) {

    const options: any[] = [];
    const resul: any[] = [];

    if ( optionsObj === null ) { return []; }
    Object.keys ( optionsObj ).forEach( key => {
      const rol: any = optionsObj[key];
      options.push( rol );
    });
    // tslint:disable-next-line: forin
    for (const prop in options[0]) {
  //  console.log( usuarios[0][prop].attributes );
    resul.push( options[0][prop].attributes );
    }

   // console.log(resul);

    return resul;

}

getExtService( id: string ) {

  const url = `${environment.URL_SERVICIOS}/ext_services/${id}?token=${this.token}&secret_key=${environment.SECRET_KEY}`;

  return this.http.get( url ).pipe(
    map ( (resp: any) => { return this.crearArregloExtService(resp);
    } ));

}

crearArregloExtService( optionObj: any) {

  const option: any[] = [];
  const resul: any[] = [];
 // console.log(usuariosObj);
  if ( optionObj === null ) { return []; }
  Object.keys ( optionObj ).forEach( key => {
    const r: any = optionObj[key];
    option.push( r );
  });
  // tslint:disable-next-line: forin
//  console.log( usuarios[0][prop].attributes );
  resul.push( optionObj.data.attributes );

  return resul;

}

actualizaExtService(ido: string, params) {
  params.secret_key = environment.SECRET_KEY;
  
 // console.log(params);
const url = `${environment.URL_SERVICIOS}/ext_services/${ido}?token=${this.token}`;

return this.http.patch( url, params ).pipe(
  map( (resp: any) => { return resp;
  } ));
}

borrarServicio(id: string) {

  const params = {
    token: this.token,
    secret_key: environment.SECRET_KEY
  }

  const url = `${ environment.URL_SERVICIOS }/ext_services/${ id }?`;

  return this.http.delete( url, {params} ).pipe(
    map( (resp: any) => { return resp;
     } ));

}

// TIPOS DE ARCHIVOS

getTiposArchivos() {

  const params = {
    token: this.token,
    secret_key: environment.SECRET_KEY
  }
  const url = `${ environment.URL_SERVICIOS }/file_types?`;

  return this.http.get( url, { params } ).pipe(
  map( (resp: any) => {return this.crearArregloTiposArchivos(resp);
  }));

  }
crearArregloTiposArchivos( optionsObj: any) {

  const options: any[] = [];
  const resul: any[] = [];

  if ( optionsObj === null ) { return []; }
  Object.keys ( optionsObj ).forEach( key => {
    const rol: any = optionsObj[key];
    options.push( rol );
  });
  // tslint:disable-next-line: forin
  for (const prop in options[0]) {
//  console.log( usuarios[0][prop].attributes );
  resul.push( options[0][prop].attributes );
  }

 // console.log(resul);

  return resul;

}

borrarTipoArchivo(id: string) {

  const params = {
    token: this.token,
    secret_key: environment.SECRET_KEY
  }

  const url = `${ environment.URL_SERVICIOS }/file_types/${ id }?`;

  return this.http.delete( url, {params} ).pipe(
    map( (resp: any) => { return resp;
     } ));

}

getTipoArchivo( id: string ) {

  const url = `${environment.URL_SERVICIOS}/file_types/${id}?token=${this.token}&secret_key=${environment.SECRET_KEY}`;

  return this.http.get( url ).pipe(
    map ( (resp: any) => { return this.crearArregloExtService(resp);
    } ));

}

actualizaTipoArchivo(ido: string, params) {
  params.secret_key = environment.SECRET_KEY;
  params.token = this.token;
const url = `${environment.URL_SERVICIOS}/file_types/${ido}?`;

return this.http.patch( url, params ).pipe(
  map( (resp: any) => { return resp;
  } ));
}

crearTipoArchivo( params ) {
  params.secret_key = environment.SECRET_KEY;
  params.token = this.token;
  const url = `${ environment.URL_SERVICIOS }/file_types?`;

  return this.http.post( url, params ).pipe(
            map( (resp: any) => {
              return resp;
            }));
}

// DOCUMENTO

getDocumentos() {

  const params = {
    token: this.token,
    secret_key: environment.SECRET_KEY
  }
  const url = `${ environment.URL_SERVICIOS }/documents?`;

  return this.http.get( url, { params } ).pipe(
  map( (resp: any) => {return this.crearArregloTiposArchivos(resp);
  }));

  }

  crearDocumento( params ) {
    params.secret_key = environment.SECRET_KEY;
    params.token = this.token;
    const url = `${ environment.URL_SERVICIOS }/documents?`; 
  
    return this.http.post( url, params ).pipe(
              map( (resp: any) => {
                return resp;
              }));
  }

  borrarDocumento(id: string) {

    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY
    }
  
    const url = `${ environment.URL_SERVICIOS }/documents/${ id }?`;
  
    return this.http.delete( url, {params} ).pipe(
      map( (resp: any) => { return resp;
       } ));
  
  }

  getDocumento( id: string ) {

    const url = `${environment.URL_SERVICIOS}/documents/${id}?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
  
    return this.http.get( url ).pipe(
      map ( (resp: any) => { return this.crearArregloExtService(resp);
      } ));
  
  }

  actualizaDocumento(ido: string, params) {
    params.secret_key = environment.SECRET_KEY;
    params.token = this.token;
  const url = `${environment.URL_SERVICIOS}/documents/${ido}?`;
  
  return this.http.patch( url, params ).pipe(
    map( (resp: any) => { return resp;
    } ));
  }

}