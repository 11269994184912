import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-modregemp',
  templateUrl: './modregemp.component.html',
  styleUrls: ['./modregemp.component.css']
})
export class ModregempComponent implements OnInit {

  usuarios: any[] = [];
  usuariosclien: any[] = [];
  usuarioscliente: any[] = [];
  //cols: any[];
  nombre = '';
  num = 0;
  cols = [
    { field: 'name', header: 'Nombre' },
    { field: 'email', header: 'Correo' },
    { field: 'job', header: 'Puesto' },
    { field: 'gender', header: 'Genero' },
    { field: 'status', header: 'Estatus' },
    { field: 'nombrerol', header: 'Rol' },
    { field: 'herramientas', header: 'Herramientas' }
];
  constructor( private usuariosservice: UsuarioService ) {
  }

  ngOnInit(): void {
    
    
    this.usuariosservice.getUsuarios().subscribe( async resp => { this.usuarios = resp;
   /* for (const prop in this.usuarios) {
      if (this.usuarios[prop].role_id !== null) {
        this.usuariosservice.getrol(this.usuarios[prop].role_id).subscribe(r => {
          this.usuarios[prop].nombrerol = r.data.attributes.name;
          if (r.data.attributes.name === 'Clientes') {
            this.usuariosclien.push(this.usuarios[prop]);
          }
          })
      } else {
        this.usuarios[prop].nombrerol = 'Sin rol asignado';
      }
      
    } */
    for await (const prop of this.usuarios) {
      swal2.showLoading();
      if (prop.role_id !== null) {
      await this.usuariosservice.getrol(prop.role_id).toPromise().then(r => {
        prop.nombrerol = r.data.attributes.name;
          if (r.data.attributes.name === 'Clientes') {
            this.usuarioscliente.push(prop);
          }
      })
      } 
    }
    this.usuariosclien = this.usuarioscliente
    swal2.close()
    } );
  }
}
