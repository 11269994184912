import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AwsService } from '../../services/awsser/aws.service';
import swal2 from 'sweetalert2';
import { Observable, Subject } from 'rxjs';
import { ContribuyentesService, UsuarioService } from 'src/app/services/service.index';
import {MatAccordion} from '@angular/material/expansion';
import { Router } from '@angular/router';
import { ListasService } from '../../services/listas/listas.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-registroempleado',
  templateUrl: './registroempleado.component.html',
  styleUrls: ['./registroempleado.component.css']
})
export class RegistroempleadoComponent implements OnInit {
   @ViewChild(MatAccordion) accordion: MatAccordion;
   // Para el control del formulario //
   showModalTerminosYCondiciones = true;
   exppanel = false;
   disablepanel = true;
   gender: any[];
   companyes: any[];
   keycompextra3: any[] = [];
   bank: any[];
   colonias: any[];
   idu: string;
   states: any[] = [];
   countries = [];
   mun: any[] = [];
   casados: string[] = ['SI', 'NO'];
   escasado: any[] = [{ val: 'CA', mos: 'SI' },{val: 'SO', mos: 'NO'}];
   regimen: any[] = [{val: 'BM', mos: 'Bienes mancomunados'}, {val: 'BS', mos: 'Bienes separados'}];
   numer: string[] = ['0','1','2','3','4'];
   tnomina: string[] = ['Semanal', 'Quincenal'];
   plazosemanal: string[] = ['52 semanas', '78 semanas', '156 semanas'];
   plazoquincenal: string[] = ['18 quincenas','24 quincenas','36 quincenas','72 quincenas'];
   foods: any[] = [
      {value: 'Factor'},
      {value: 'Otra empresa'},
      {value: 'Y otra'},
    ];
    totalPercepciones = 0;
    totalDeducciones = 0;
   regUsuario =  {
      datosPersonales: {
         nombre: '',sexo: '',fechanacimiento: '',edad: '',pais: '',paisres: '',nacionalidad: '',RFC: '',CURP: '',
         identificacion: '',nidentificacion: '',telefono: '',casado: 'SO',regimen: '',dependientes: 'NO',menores:'0',mayores:'0',
         imms: '', apellidom: '', apellidop: ''
      },
      datosDomicilio: {
         calle: '',nexterior: '',ninterior: '',colonia: '',cp: '',delymun: '',ciudad: '',paisdom: '',paisdomlet: '',tvivienda: 'Rentada',
         telparticular: '',telmovil: '',mailface: '', referencia: '', house_rent: '0.00'
      },
      datosEmpleoAct: {
         empresadat: '',puestoactualemp: '',actividademp: '',calleemp: '',coloniaemp: '',cpemp: '',delymunemp: '',ciudademp: '',
         estadoemp: '',telefonoemp: '', companyid: '', rfcemp: '', arrdata: '', extra3: null, contolextra3: '', controlnss: true
      },
      refPers: {
         nombrenf1: '', apellidopnf1:'', apellidomnf1: '',domnf1: '',telnf1: '',
         nombrenf2: '', apellidopnf2:'', apellidomnf2: '',domnf2: '',telnf2: '',
         nombrefam: '', apellidopfam:'', apellidomfam: '',domfam: '',telfam: ''
      },
      egrydatban: {
         sueldomens: 0,oingresos: 0,esoingresos: 'N/A',ncuentabanco: '',nombrebanco: '',clabebanco: '', supertotal: '', bpuntualidad: '', basistencia: '',
         valesdespensa: '', impuestos: '', fahorro: '', infonavit: '', retimss: '', pfahorro: 0, cajaahorro: 0, dseguro: 0, extraordinario: 0, palimentaria: 0,
         extra1: '', controlnombrebanco: false, totalPercepciones: this.totalPercepciones, totalDeducciones: this.totalDeducciones, detail_extra_income: '', detail_extra_deductions: ''
      },
      datosLaborales: {
         empresa: '',antiguedad:'',puesto: '',jefe:'',formaPago: '',psemanal: '',pquincenal: '', plazocredito: ''
      },
      datosCredito: {
         monto: '',destino: '',anualidad: 'NO',montoanualidad: ''
      },
      kyc: {
      public_charge: 'NO', public_charge_det: '', relative_charge: 'NO', relative_charge_det: '', benefit: 'NO', benefit_detail: '', responsible: 'NO', responsible_detail: ''
      }
     
   }
   kyc = this._formBuilder.group({
    public_charge_det: [``, Validators.required],
    benefit_detail: [``, Validators.required],
    responsible_detail: [``, Validators.required],
    relative_charge_det: [``, Validators.required]
  });
   firstFormGroup = this._formBuilder.group({
      nombre: [``, []],
      edad: ['', Validators.required],
      rfc: ['', Validators.required],
      fnac: [``, Validators.required],
      imss: ['', Validators.required],
      curp: ['', Validators.required],
      pais: ['', Validators.required],
      paisres: ['', Validators.required],
      nidentificacion: ['', Validators.required],
      nacionalidad: ['', Validators.required],
      apellidop: ['', []],
      apellidom: ['', []],
      sex: ['', Validators.required],
      iden: ['', Validators.required],
    });
    domFormGroup = this._formBuilder.group({
      calle: ['', Validators.required],
      nexterior: ['', Validators.required],
      cp: ['', Validators.required],
      paisdom: ['', Validators.required],
      paisdomlet: ['', []],
      telmovil: ['', Validators.required],
      edo: ['', Validators.required],
      mun: ['', Validators.required],
      col: ['', Validators.required],
    });
    egrcredFormGroup = this._formBuilder.group({
      ncbanco: ['', Validators.required], 
      nbanco: ['', Validators.required],
      clabebanco: ['', Validators.required], 
      bpuntualidad: ['', Validators.required], 
      basistencia: ['', Validators.required], 
      valesdespensa: ['', Validators.required], 
      fahorro: ['', Validators.required], 
      infonavit: ['', Validators.required], 
      impuestos: ['', Validators.required], 
      retimss: ['', Validators.required], 
      sueldomens: ['', Validators.required], 
      clavebanca: ['', []],
      creddes: ['', Validators.required]
    });
    datempFormGroup = this._formBuilder.group({
      antigu: ['', []],
      nombrenf1: ['', Validators.required],
      nombrenf2: ['', Validators.required],
      nombrefam: ['', Validators.required],
      apellidopnf1: ['', Validators.required],
      apellidopnf2: ['', Validators.required],
      apellidopfam: ['', Validators.required],
      apellidomnf1: ['', Validators.required],
      apellidomnf2: ['', Validators.required],
      apellidomfam: ['', Validators.required],
      domnf1: ['', Validators.required],
      domnf2: ['', Validators.required],
      domfam: ['', Validators.required],
      telnf1: ['', Validators.required],
      telnf2: ['', Validators.required],
      telfam: ['', Validators.required],
      empress: ['', []]
    });
    secondFormGroup = this._formBuilder.group({
      empresa: ['', Validators.required]
    });
    tirdFormGroup = this._formBuilder.group({
      destino: ['', Validators.required],
      monto: ['', Validators.required],
    });
  // Para el control de los archivos a subir //  
  archivoNombres = {
    INE: '',
    COMPDOM: '',
    CURP: '',
    CSF: '',
    XML: '',
    ECUENTA: ''
  }
  dataXml = {
    imss: [],
    cfdiEmisor: {
      Nombre: '',
      RegimenFiscal: '',
      Rfc: ''
    },
    cfdiReceptor: {
      Nombre: '',
      Rfc: '',
      UsoCFDI: ''
    }

  } 
  images = [];
  files = [];
  filesNames = [];
  lists = [];
  periodicidadPago = [];
  capturaCp;
  percepciones = [
    'Salario semanal',
    'Sueldo Quincenal',
    'Bono Puntualidad',
    'Bono Asistencia',
    'Vales de Despensa',   
  ];
  deducciones = [
    'Aportación trabaj  IMSS',
    'Fondo Ahorro Empleado',
    'Apor  crédito INFONAVIT',
    'Total de impuestos',
  ];

   myForm = new FormGroup({
    filesIngresos: new FormControl(),
    fileIne: new FormControl(),
    fileCompDom: new FormControl(),
    fileCurp: new FormControl(),
    fileCsf: new FormControl(),
    fileXml: new FormControl(),
    fileEcuenta: new FormControl()
  });
  imagen = '';
  empresa = false;
  houseType = [];
  bancos: any[];
  esj = false;
  usuario: any;
  detalleDeducciones = [];
  detallePercepciones = [];
  
  constructor(private cont: ContribuyentesService,
              private listasService: ListasService,
              private router: Router,
              private aws: AwsService,
              private _formBuilder: FormBuilder,
              private usuarioService: UsuarioService) {
               
  }

  ngOnInit(): void {
    this.kyc.get('public_charge_det').clearValidators();
    this.kyc.get('public_charge_det').updateValueAndValidity();
    this.kyc.get('benefit_detail').clearValidators();
    this.kyc.get('benefit_detail').updateValueAndValidity();
    this.kyc.get('responsible_detail').clearValidators();
    this.kyc.get('responsible_detail').updateValueAndValidity();
    this.kyc.get('relative_charge_det').clearValidators();
    this.kyc.get('relative_charge_det').updateValueAndValidity();
   if (localStorage.getItem('uid')) {
      this.idu = localStorage.getItem('uid')
      this.usuarioService.getusuario(this.idu).subscribe(resp => {
        this.usuario = resp[0];
      })
   } else {
      this.idu = 'db9a7967-723b-4ac1-b684-d6a217fc39a2';
   }
   if (localStorage.getItem('emailuser')) {
      this.regUsuario.datosDomicilio.mailface = localStorage.getItem('emailuser');
   }

   this.aws.getCountriesD().subscribe( resp => { this.countries = resp; } );
  //  this.aws.getStates().subscribe( resp => { this.states = resp; } );
   this.aws.getUserGender().subscribe(resp => { this.gender = resp.data})
  //  this.aws.getPersonBank().subscribe(resp => { this.bank = resp.data;})
   this.aws.getCompanyes().subscribe(resp => {
    this.companyes = resp.filter(r => r.rfc != 'CDE1210163W1');
  });
  /* this.aws.getCompanyByUser(this.idu).subscribe(r => {
      if (r.length > 0) {
         this.regUsuario.datosEmpleoAct.companyid = r[0].company_uuid;
         this.regUsuario.datosEmpleoAct.empresadat = r[0].compañia
      } else {
         this.regUsuario.datosEmpleoAct.companyid = '33829373-c97a-41b0-a512-148eace0e285'
      this.regUsuario.datosEmpleoAct.empresadat = 'COPACHISA'
      }
   
   }, (err) => {
      this.regUsuario.datosEmpleoAct.companyid = '33829373-c97a-41b0-a512-148eace0e285'
      this.regUsuario.datosEmpleoAct.empresadat = 'COPACHISA'
   }) */
   //this.egrcredFormGroup.disable();
   this.listasService.getLists().subscribe(lists => {
    lists['data'].forEach(list => {
      this.lists.push(list.attributes);
    });
    this.periodicidadPago = this.lists.filter(p => p.domain === 'PERIODICIDAD_PAGO');
    this.houseType = this.lists.filter(p => p.domain === 'HOUSE_TYPE');
    this.bank = this.lists.filter(p => p.domain === 'PERSON_BANK');
  });
  }

  changeBank(item){
    console.log(item)
  }

  removeAccents(str){
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
  } 

  descontrol() {
    if (this.regUsuario.kyc.public_charge === 'SI') {
      this.kyc.get('public_charge_det').setValidators([Validators.required]);
      this.kyc.get('public_charge_det').updateValueAndValidity();
    } else {
      this.kyc.get('public_charge_det').clearValidators();
      this.kyc.get('public_charge_det').updateValueAndValidity();
    }
  }
  descontrol2() {
    if (this.regUsuario.kyc.benefit === 'SI') {
      this.kyc.get('benefit_detail').setValidators([Validators.required]);
      this.kyc.get('benefit_detail').updateValueAndValidity();
    } else {
      this.kyc.get('benefit_detail').clearValidators();
      this.kyc.get('benefit_detail').updateValueAndValidity();
    }
  }
  descontrol3() {
    if (this.regUsuario.kyc.responsible === 'SI') {
      this.kyc.get('responsible_detail').setValidators([Validators.required]);
      this.kyc.get('responsible_detail').updateValueAndValidity();
    } else {
      this.kyc.get('responsible_detail').clearValidators();
      this.kyc.get('responsible_detail').updateValueAndValidity();
    }
  }
  descontrol4() {
    if (this.regUsuario.kyc.relative_charge === 'SI') {
      this.kyc.get('relative_charge_det').setValidators([Validators.required]);
      this.kyc.get('relative_charge_det').updateValueAndValidity();
    } else {
      this.kyc.get('relative_charge_det').clearValidators();
      this.kyc.get('relative_charge_det').updateValueAndValidity();
    }
  }
  async buscakeycompany(namecompany) {
   if (namecompany.toLowerCase() === 'alsuper') {
      this.aws.getKeyAlsuper().subscribe(r => {this.keycompextra3 = r.data; console.log(r.data) })
     if (this.regUsuario.datosEmpleoAct.controlnss === true) {
      this.regUsuario.datosEmpleoAct.extra3 = this.regUsuario.datosEmpleoAct.contolextra3;
     } else {
      swal2.fire({
        title: 'No te encuentras en la lista de empleados autorizados para solicitar un crédito', 
        text: 'favor de revisar con el área de recursos humanos. ', 
        icon: 'info',
        showConfirmButton: true,
        allowOutsideClick: false
      }).then(res => {
        if(res.value){
          // this.ngOnInit();
          window.location.reload();
        }
      });
     }
     
   } else {
      this.regUsuario.datosEmpleoAct.extra3 = null;
      
   }
  }

  checanss(nss) {
    console.log(nss);
    this.aws.getInfoEmpNss(this.regUsuario.datosPersonales.imms).subscribe(r => {console.log(r);
      this.regUsuario.datosEmpleoAct.controlnss = true
    },(err) => {
      console.log('el error')
      this.regUsuario.datosEmpleoAct.controlnss = false
    })
  }

  async reguserprue() {
   swal2.showLoading();
   let taresp: any[] = [];
   let archivossubidos = JSON.parse(localStorage.getItem('Documents'));
   (await this.aws.creauserprueba()).subscribe(async r => {
      (await this.aws.getDocuments(r.data.relations.contributors[0].id)).subscribe(async r => {
       let resp = await this.actualizaUrls(r,archivossubidos);
       swal2.fire({
         title: 'Exito', 
         text: 'Informacion registrada correctamente', 
         icon: 'success',
         showConfirmButton: true,
         allowOutsideClick: false
       }).then(res => {
         if(res.value){
           // this.ngOnInit();
           window.location.reload();
         }
       });
       swal2.fire('Informacion registrada con exito','','info')
      })
   })
   
  }

  async actualizaUrls(r, archivos) {
   let taresp: any[] = [];
        for await (let prop of r.data) {
          console.log(prop.attributes.name);
         switch (prop.attributes.name) {
            case 'Identificacion oficial INE':
              try {
               (await this.aws.patchUrl(prop.attributes.contributor_id, prop.attributes.id, archivos.fileIne.resp.Location)).subscribe()
               let resp = {
                 status: true,
                 msg: 'INE'
               }
               taresp.push(resp);
             } catch (error) {
                 let resp = {
                     status: false,
                     resp: error,
                     msg: 'INE'
                   }
               taresp.push(resp);  
             }
             break;
             case 'Comprobante de domicilio':
              try {
               (await this.aws.patchUrl(prop.attributes.contributor_id,prop.attributes.id, archivos.fileCompDom.resp.Location)).subscribe()
               let resp = {
                 status: true,
                 msg: 'Comprobante domicilio'
               }
               taresp.push(resp);
             } catch (error) {
                 console.log(error)
                 let resp = {
                     status: false,
                     resp: error,
                     msg: 'Comprobante domicilio'
                   }
               taresp.push(resp);  
             }
             break;
             case 'Curp del empleado':
              try {
                (await this.aws.patchUrl(prop.attributes.contributor_id,prop.attributes.id, archivos.afileCurp.resp.Location)).subscribe()
               let resp = {
                 status: true,
                 msg: 'CURP'
               }
               taresp.push(resp);
             } catch (error) {
                 console.log(error)
                 let resp = {
                     status: false,
                     resp: error,
                     msg: 'CURP'
                   }
               taresp.push(resp);  
             }
             break;
             case 'Estado de cuenta bancario':
              try {
                (await this.aws.patchUrl(prop.attributes.contributor_id,prop.attributes.id, archivos.fileEcuenta.resp.Location)).subscribe()
               let resp = {
                 status: true,
                 msg: 'ECUENTA'
               }
               taresp.push(resp);
             } catch (error) {
                 console.log(error)
                 let resp = {
                     status: false,
                     resp: error,
                     msg: 'CURP'
                   }
               taresp.push(resp);  
             }
             break;
             case 'Constancia de situación fiscal':
              // if (archivos.fileRfc.msg === "Archivo subido correctamente") {
                  try {
                     (await this.aws.patchUrl(prop.attributes.contributor_id,prop.attributes.id, archivos.fileCsf.resp.Location)).subscribe()
                     let resp = {
                       status: true,
                       msg: 'CSF'
                     }
                     taresp.push(resp);
                   } catch (error) {
                       console.log(error)
                       let resp = {
                           status: false,
                           resp: error,
                           msg: 'CSF'
                         }
                     taresp.push(resp);  
                   }
                   
             //  }
               break;
             case 'XML del empleado':
              try {
               (await this.aws.patchUrl(prop.attributes.contributor_id,prop.attributes.id, archivos.fileXml.resp.Location)).subscribe()
               let resp = {
                 status: true,
                 resp: r,
                 msg: 'XML'
               }
               taresp.push(resp);
             } catch (error) {
                 console.log(error)
                 let resp = {
                     status: false,
                     resp: error,
                     msg: 'XML'
                   }
               taresp.push(resp);  
             }
             break;
             case 'Comprobantes de ingresos del empleado':
              try {
               (await this.aws.patchUrl(prop.attributes.contributor_id,prop.attributes.id, archivos.filesIngresos[archivos.filesIngresos.length-1])).subscribe()
               let resp = {
                 status: true,
                 resp: r,
                 msg: 'Comprobantes de ingresos'
               }
               taresp.push(resp);
             } catch (error) {
                 console.log(error)
                 let resp = {
                     status: false,
                     resp: error,
                     msg: 'Comprobantes de ingresos'
                   }
               taresp.push(resp);  
             }
             break;
          }
        }
        console.log(taresp);
         return taresp;
  }

  buscaEdo(id) {
   this.aws.getStatesId(id).subscribe( r => {this.states = r;} )
  }

  buscaMun(id) {
   this.aws.getMunicipios(id).subscribe( r => {this.mun = r;} )
  }
  getColonias(event) {
   this.aws.getColoniasxcp(event).subscribe(r => {
      this.colonias = r;
      this.capturaCp = false;
      if(this.colonias.length == 0){
        this.capturaCp = true;
      }
    })
  }
  async pruebacopa() {
   //this.aws.pruebacopa().subscribe( r => { console.log(r) } )
    (await this.aws.CreatePFcustomer(this.regUsuario, this.idu)).subscribe( r => console.log(r) )
  }



  // Funciones para asignaciones de datos capturados por el usuario //
  async regUsr() {
   swal2.showLoading();
   if (this.regUsuario.datosEmpleoAct.arrdata[2].toLowerCase() === 'alsuper' && (this.regUsuario.datosEmpleoAct.extra3 === null || this.regUsuario.datosEmpleoAct.extra3 === '' ) ) {
      swal2.fire('Debe seleccionar un puesto de la seccion Datos del empleo actual y referencias personales para continuar','','info');
      return;
   }
   let archivossubidos = JSON.parse(localStorage.getItem('Documents'));
   this.regUsuario.egrydatban.supertotal = (parseFloat(this.regUsuario.egrydatban.sueldomens.toString()) + parseFloat(this.regUsuario.egrydatban.oingresos.toString())).toFixed(2);
   /* this.regUsuario.datosEmpleoAct.companyid = this.regUsuario.datosEmpleoAct.arrdata[0];
   this.regUsuario.datosEmpleoAct.rfcemp = this.regUsuario.datosEmpleoAct.arrdata[1];
   this.regUsuario.datosEmpleoAct.empresadat = this.regUsuario.datosEmpleoAct.arrdata[2]; */
   this.regUsuario.egrydatban.totalDeducciones = this.totalDeducciones;
   this.regUsuario.egrydatban.totalPercepciones = this.totalPercepciones;
   this.regUsuario.egrydatban.detail_extra_income = JSON.stringify(this.detallePercepciones);
   this.regUsuario.egrydatban.detail_extra_deductions = JSON.stringify(this.detalleDeducciones);
   this.regUsuario.datosPersonales.apellidop = this.removeAccents(this.regUsuario.datosPersonales.apellidop);
   this.regUsuario.datosPersonales.apellidom = this.removeAccents(this.regUsuario.datosPersonales.apellidom);
   this.regUsuario.datosPersonales.nombre = this.removeAccents(this.regUsuario.datosPersonales.nombre);
   console.log(this.regUsuario);
  (await this.aws.CreatePFcustomer(this.regUsuario, this.idu)).subscribe( async res => {
   (await this.aws.getDocuments(res.data.relations.contributors[0].id)).subscribe(async r => {
      let resp = await this.actualizaUrls(r,archivossubidos);
      swal2.fire({
         title: 'Exito', 
         text: 'Información registrada correctamente', 
         icon: 'success',
         showConfirmButton: true,
         allowOutsideClick: false
       }).then(res => {
         if(res.value){
           // this.ngOnInit();
           this.router.navigate([`/maincliente/solicitudcredito/${this.idu}`]);
         }
       });
     })
   }, (err) => {
      console.log(err)
      let error = '';
      try{
        Object.keys(err.error.error).forEach(e => {
          var v = err.error.error;
          error += e + ': ' + v[e][0] + '\n';
          swal2.fire('ocurrio un error',error,'error')
        })
      }catch{
        try{
          error = err.error.errors[0];
          swal2.fire('ocurrio un error',error,'error')
        }catch{
          swal2.fire('ocurrio un error','Contacte al Administrador','error')
          
        }
      }
      /* for (const prop in err.error.error) {
         if (err.error.error.birthdate) {
            msgerr = 'Error en fecha de nacimiento, no puede ser vacia, revise datos personales '
         } else if (err.error.error.rfc) {
            msgerr = 'Error en RFC, ya esta registrado, revise datos personales '
         }else if (err.error.error.municipality) {
            msgerr = 'Error en Municipio, debe escoger uno, revise domicilio particular '
         }else if (err.error.error.state) {
            msgerr = 'Error en Estado, debe escoger uno, revise domicilio particular '
         }else if (err.error.error.suburb) {
            msgerr = 'Error en Colonia, debe escoger una, revise domicilio particular '
         }
      } */
   } ) 
  }
  casado(cas) {
   if (cas === 'SI') {
   this.regUsuario.datosPersonales.casado = 'CA';
   } else {
      this.regUsuario.datosPersonales.casado = 'SO';
      this.regUsuario.datosPersonales.regimen = '';
   }
  }

  dependientes(cas) {
   if (cas === 'SI') {
      this.regUsuario.datosPersonales.dependientes = 'SI';
      } else {
         this.regUsuario.datosPersonales.dependientes = 'NO';
         this.regUsuario.datosPersonales.menores = '';
         this.regUsuario.datosPersonales.mayores = '';
      }
  }

  regi(reg) {
   if (reg === 'Bienes mancomunados') {
      this.regUsuario.datosPersonales.regimen = 'BM';
   } else {
      this.regUsuario.datosPersonales.regimen = 'BS';
   }
  }

  men(m) {
   this.regUsuario.datosPersonales.menores = m;
  }

  may(m) {
   this.regUsuario.datosPersonales.mayores = m;
  }

  pago(cas) {
   this.regUsuario.datosLaborales.formaPago = cas;
  }

  psemanal(p) {
   this.regUsuario.datosLaborales.psemanal = p;
  }
  pquincenal(p) {
   this.regUsuario.datosLaborales.pquincenal = p;
  }
  sexo(s) {
   this.regUsuario.datosPersonales.sexo = s;
  }
  anualidad(a) {
   if (a === 'SI') {
      this.regUsuario.datosCredito.anualidad = 'SI';
      } else {
         this.regUsuario.datosCredito.anualidad = 'NO';
         this.regUsuario.datosCredito.montoanualidad = '';
      }
  }

  // eventos para el control y vista para los archivos a subir //
  dragEnter(id: string) {
        document.getElementById(id).style.border = "dashed 2px blue";
  }
  dragLeave(id: string) {
        document.getElementById(id).style.border = "dashed 1px #979797";
  }
  drop(id: string) {
    document.getElementById(id).style.border = "dashed 1px #979797";
  }

  fileBrowseHandler( e: any, doc: string ) {
    switch (doc) {
      case 'INE':
        this.archivoNombres.INE = e[0].name;
        this.myForm.patchValue({ fileIne: e[0] });
       break;
      case 'COMPDOM':
        if ((e[0].type).toLowerCase().includes('pdf')) {
          this.archivoNombres.COMPDOM = e[0].name;
          this.myForm.patchValue({ fileCompDom: e[0] });
       break;
        } else {
          swal2.fire('El archivo tiene que ser un PDF','','info');
          break;
        }
       case 'CURP':
        if ((e[0].type).toLowerCase().includes('pdf')) {
          this.archivoNombres.CURP = e[0].name;
          this.myForm.patchValue({ fileCurp: e[0] });
         break;
        } else {
          swal2.fire('El archivo tiene que ser un PDF','','info');
          break;
        }
       case 'CSF':
        if ((e[0].type).toLowerCase().includes('pdf')) {
          this.archivoNombres.CSF = e[0].name;
          this.myForm.patchValue({ fileCsf: e[0] });
          break;
        } else {
          swal2.fire('El archivo tiene que ser un PDF','','info');
          break;
        }
       case 'XML':
        if ((e[0].type).toLowerCase().includes('xml')) {
          this.myForm.patchValue({ fileXml: e[0] });
          this.readXml(e[0].name)
       break;
        } else {
          swal2.fire('El archivo tiene que ser un XML','','info');
          break;
        } 
        case 'ECUENTA':
        if ((e[0].type).toLowerCase().includes('pdf')) {
          this.archivoNombres.ECUENTA = e[0].name;
          this.myForm.patchValue({ fileEcuenta: e[0] });
       break;
        } else {
          swal2.fire('El archivo tiene que ser un PDF','','info');
          break;
        }
    }
  }

  get f(){
    return this.myForm.controls;
  }

   onFileChange(event: any) {
    if ((event.target.files[0].type).toLowerCase().includes('pdf')) {
      if (event.target.files && event.target.files[0]) {

        this.files.push(event.target.files[0]);
         this.filesNames.push(event.target.files[0].name)
        /* if (this.files.length === 0) {
         this.files.push(event.target.files[0]);
         this.filesNames.push(event.target.files[0].name)
        } 
        else {
           if (!this.filesNames.includes(event.target.files[0].name)) {
             this.files.push(event.target.files[0]);
             this.filesNames.push(event.target.files[0].name)
           }
        } */
         this.myForm.patchValue({
           filesIngresos: this.files
        });
       }
    } else {
      swal2.fire('El archivo tiene que ser un PDF','','info');
    }
    
    
  }

  readXml(nombre){
    
    const file = this.myForm.value.fileXml;
    this.readFileXml(file).subscribe((doc: any) => {
      let jsonxml = this.xml2json(doc,'');
      var percepciones = JSON.parse(jsonxml)["cfdi:Comprobante"]['cfdi:Complemento']['nomina12:Nomina']['nomina12:Percepciones']['nomina12:Percepcion'];
      var deducciones = JSON.parse(jsonxml)["cfdi:Comprobante"]['cfdi:Complemento']['nomina12:Nomina']['nomina12:Deducciones']['nomina12:Deduccion'];
      this.totalPercepciones = 0;
      this.totalDeducciones = 0
      percepciones.forEach(p => {
        if(!this.percepciones.includes(p.Concepto)){
          var importes = parseFloat(p.ImporteExento) + parseFloat(p.ImporteGravado)
          this.totalPercepciones += importes;
          this.detallePercepciones.push(p.Concepto);
        }
      });
      deducciones.forEach(d => {
        if(!this.deducciones.includes(d.Concepto)){
          var importes = parseFloat(d.Importe)
          this.totalDeducciones += importes;
          this.detalleDeducciones.push(d.Concepto);
        }
      });
      if (((JSON.parse(jsonxml))["cfdi:Comprobante"]) === undefined) {
        swal2.fire('Error de lectura XML: mal formado', 'Consulte al administrador', 'warning')
        return;
      }
        if (((JSON.parse(jsonxml))["cfdi:Comprobante"]).hasOwnProperty('parsererror')) {
          swal2.fire('Error de lectura XML: mal formado', 'Consulte al administrador', 'warning')
          return;
        }
      var fechaComprobante = new Date(JSON.parse(jsonxml)["cfdi:Comprobante"].Fecha);
      var fechaHoy = new Date();
      var diff = fechaHoy.getTime() - fechaComprobante.getTime();
      var diff_dias = diff/(1000*60*60*24);
      if(diff_dias < 30){
      //  console.log(JSON.parse(jsonxml['cfdi:Comprobante']).hasOwnProperty('parsererror'))
       /* if (JSON.parse(jsonxml)["cfdi:Comprobante"]["cfdi:Emisor"].Rfc) {
          if (JSON.parse(jsonxml)["cfdi:Comprobante"]["cfdi:Emisor"].Rfc === 'ESJ010227U51' || JSON.parse(jsonxml)["cfdi:Comprobante"]["cfdi:Emisor"].Rfc === 'ESA010207RV1') {
            this.regUsuario.datosLaborales.formaPago = 'Quincenal';
            this.esj = true;
            return;
          }
        }*/
        try {
          this.regUsuario.datosPersonales.imms = this.getValues(JSON.parse(jsonxml), "NumSeguridadSocial")[0];
        if (this.regUsuario.datosPersonales.imms !== undefined) {
          this.firstFormGroup.get('imss').clearValidators();
          this.firstFormGroup.get('imss').updateValueAndValidity();
          this.firstFormGroup.get('imss').disable();
          // COMENTADO PORQUE ERA PARA ALSUPER
          /* this.aws.getInfoEmpNss(this.regUsuario.datosPersonales.imms).subscribe(r => {
          this.regUsuario.datosPersonales.nombre = r.data.attributes.nombre;
          this.firstFormGroup.get('nombre').clearValidators();
          this.firstFormGroup.get('nombre').updateValueAndValidity();
          this.firstFormGroup.get('nombre').disable();
          this.regUsuario.datosPersonales.apellidop = r.data.attributes.primer_apellido;
          this.firstFormGroup.get('apellidop').clearValidators();
          this.firstFormGroup.get('apellidop').updateValueAndValidity();
          this.firstFormGroup.get('apellidop').disable();
          if (r.data.attributes.segundo_apellido !== null ) {
          this.regUsuario.datosPersonales.apellidom = r.data.attributes.segundo_apellido;
          this.firstFormGroup.get('apellidom').clearValidators();
          this.firstFormGroup.get('apellidom').updateValueAndValidity();
          this.firstFormGroup.get('apellidom').disable();
          }
          if (r.data.attributes.banco !== null ) {
          this.regUsuario.egrydatban.nombrebanco = r.data.attributes.banco.toUpperCase();
          this.regUsuario.egrydatban.controlnombrebanco = true;
          this.egrcredFormGroup.get('nbanco').clearValidators();
          this.egrcredFormGroup.get('nbanco').updateValueAndValidity();
          this.egrcredFormGroup.get('nbanco').disable();
            } else {
              this.regUsuario.egrydatban.controlnombrebanco = false;
            }
          if (r.data.attributes.fecha_ingreso !== null ) {
            let dateIngreso = new Date(r.data.attributes.fecha_ingreso);
            let dateActual = new Date();
            let dateDif = (dateActual.getTime() - dateIngreso.getTime()) / 1000 / (3600 * 24);
            this.regUsuario.datosLaborales.antiguedad = Math.abs(Math.round(dateDif / 365.25)).toString();
            this.datempFormGroup.get('antigu').clearValidators();
            this.datempFormGroup.get('antigu').updateValueAndValidity();
            this.datempFormGroup.get('antigu').disable();
            }
          if (r.data.attributes.tipo_puesto !== null ) {
          this.regUsuario.datosEmpleoAct.contolextra3 = r.data.attributes.tipo_puesto;
              }
          },(err) => {
            console.log(err)
            this.regUsuario.datosEmpleoAct.controlnss = false;
            //this.regUsuario.egrydatban.controlnombrebanco = false;
          }
          ) */
          this.regUsuario.datosEmpleoAct.controlnss = false;
        } else { this.regUsuario.datosPersonales.imms = '' }
        } catch (error) {
          this.regUsuario.datosPersonales.imms = ''
        }
         
         try {
           this.regUsuario.datosPersonales.CURP = this.getValues(JSON.parse(jsonxml), "Curp")[0];
         if (this.regUsuario.datosPersonales.CURP !== undefined) {
           this.firstFormGroup.get('curp').clearValidators();
           this.firstFormGroup.get('curp').updateValueAndValidity();
           this.firstFormGroup.get('curp').disable();
         } else { this.regUsuario.datosPersonales.CURP = '' }
         } catch (error) {
           this.regUsuario.datosPersonales.CURP = ''
         }
         
         try {
           this.regUsuario.egrydatban.retimss = this.getObjects(JSON.parse(jsonxml), 'Concepto', "Aportación trabaj  IMSS")[0].Importe
         if (this.regUsuario.egrydatban.retimss !== undefined) {
           this.egrcredFormGroup.get('retimss').clearValidators();
           this.egrcredFormGroup.get('retimss').updateValueAndValidity();
           this.egrcredFormGroup.get('retimss').disable();
         } else { this.regUsuario.egrydatban.retimss = '' }
         } catch (error) {
           this.regUsuario.egrydatban.retimss = ''
         }
         
         try {
           this.regUsuario.datosPersonales.RFC = JSON.parse(jsonxml)["cfdi:Comprobante"]["cfdi:Receptor"].Rfc;
         if (this.regUsuario.datosPersonales.RFC !== undefined) {
           this.firstFormGroup.get('rfc').clearValidators();
           this.firstFormGroup.get('rfc').updateValueAndValidity();
           this.firstFormGroup.get('rfc').disable();
         } else { this.regUsuario.datosPersonales.RFC = '' }
         } catch (error) {
           this.regUsuario.datosPersonales.RFC = ''
         }
  
         try {
           var claveFormaPago = JSON.parse(jsonxml)["cfdi:Comprobante"]["cfdi:Complemento"]["nomina12:Nomina"]["nomina12:Receptor"].PeriodicidadPago;
           if (claveFormaPago !== undefined) {
           var forma = this.periodicidadPago.find(p => p.key == claveFormaPago).value;
           forma = forma.trim();
           forma = forma.toLowerCase();
           forma = forma[0].toUpperCase() + forma.slice(1);
           this.regUsuario.datosLaborales.formaPago = forma;
          //  this.regUsuario.datosEmpleoAct.arrdata[2].toLowerCase() === 'alsuper'
           } else {this.regUsuario.datosLaborales.formaPago = 'Semanal';}
         } catch (error) {
          
           this.regUsuario.datosLaborales.formaPago = 'Semanal';
         }
  
  
         
         try {
           this.regUsuario.egrydatban.bpuntualidad = this.getObjects(JSON.parse(jsonxml), 'Concepto', 'Bono Puntualidad')[0].ImporteGravado
         if (this.regUsuario.egrydatban.bpuntualidad !== undefined) {
           this.egrcredFormGroup.get('bpuntualidad').clearValidators();
           this.egrcredFormGroup.get('bpuntualidad').updateValueAndValidity();
           this.egrcredFormGroup.get('bpuntualidad').disable();
         } else { this.regUsuario.egrydatban.bpuntualidad = '' }
         } catch (error) {
           this.regUsuario.egrydatban.bpuntualidad = ''
         }
         
         try {
           this.regUsuario.egrydatban.basistencia = this.getObjects(JSON.parse(jsonxml), 'Concepto', 'Bono Asistencia')[0].ImporteGravado
         if (this.regUsuario.egrydatban.basistencia !== undefined) {
           this.egrcredFormGroup.get('basistencia').clearValidators();
           this.egrcredFormGroup.get('basistencia').updateValueAndValidity();
           this.egrcredFormGroup.get('basistencia').disable();
         } else { this.regUsuario.egrydatban.basistencia = '' }
         } catch (error) {
           this.regUsuario.egrydatban.basistencia = ''
         }
        
         try {
           this.regUsuario.egrydatban.valesdespensa = this.getObjects(JSON.parse(jsonxml), 'Concepto', 'Vales de Despensa')[0].ImporteExento
           if (this.regUsuario.egrydatban.valesdespensa !== undefined) {
             this.egrcredFormGroup.get('valesdespensa').clearValidators();
             this.egrcredFormGroup.get('valesdespensa').updateValueAndValidity();
             this.egrcredFormGroup.get('valesdespensa').disable();
           } else { this.regUsuario.egrydatban.valesdespensa = '' }
         } catch (error) {
           this.regUsuario.egrydatban.valesdespensa = ''
         }
         
         try {
           this.regUsuario.egrydatban.sueldomens = this.getObjects(JSON.parse(jsonxml), 'TipoPercepcion', '001')[0].ImporteGravado
         if (this.regUsuario.egrydatban.sueldomens !== undefined) {
  
           this.egrcredFormGroup.get('sueldomens').clearValidators();
           this.egrcredFormGroup.get('sueldomens').updateValueAndValidity();
           this.egrcredFormGroup.get('sueldomens').disable();
         } else { this.regUsuario.egrydatban.sueldomens = 0 }
         } catch (error) {
           this.regUsuario.egrydatban.sueldomens = 0
         }
  
         try {
           this.regUsuario.egrydatban.fahorro = this.getObjects(JSON.parse(jsonxml), 'Concepto', 'Fondo Ahorro Empleado')[0].Importe
         if (this.regUsuario.egrydatban.fahorro !== undefined) {
           this.egrcredFormGroup.get('fahorro').clearValidators();
           this.egrcredFormGroup.get('fahorro').updateValueAndValidity();
           this.egrcredFormGroup.get('fahorro').disable();
         } else { this.regUsuario.egrydatban.fahorro = '' }
         } catch (error) {
           this.regUsuario.egrydatban.fahorro = ''
         }
         
         try {
           this.regUsuario.egrydatban.infonavit = this.getObjects(JSON.parse(jsonxml), 'Concepto', 'Apor  crédito INFONAVIT')[0].Importe
           if (this.regUsuario.egrydatban.infonavit !== undefined) {
              this.egrcredFormGroup.get('infonavit').clearValidators();
              this.egrcredFormGroup.get('infonavit').updateValueAndValidity();
              this.egrcredFormGroup.get('infonavit').disable();
            } else { 
              this.regUsuario.egrydatban.infonavit = '0';
              this.egrcredFormGroup.get('infonavit').disable();
           }
         } catch (error) {
           this.regUsuario.egrydatban.infonavit = '0';
           this.egrcredFormGroup.get('infonavit').disable();
         }
         
         try {
           this.regUsuario.egrydatban.impuestos = this.getValues(JSON.parse(jsonxml), "TotalImpuestosRetenidos")[0]
         if (this.regUsuario.egrydatban.impuestos !== undefined) {
           this.egrcredFormGroup.get('impuestos').clearValidators();
           this.egrcredFormGroup.get('impuestos').updateValueAndValidity();
           this.egrcredFormGroup.get('impuestos').disable();
         } else { this.regUsuario.egrydatban.impuestos = '' }
         } catch (error) {
           this.regUsuario.egrydatban.impuestos = ''
         }

         try{
          this.regUsuario.datosLaborales.puesto = JSON.parse(jsonxml)["cfdi:Comprobante"]["cfdi:Complemento"]["nomina12:Nomina"]["nomina12:Receptor"].Puesto;
         }catch(error){
          console.log(error);
         }


         try{
          var fechaInicioRelacion = new Date(JSON.parse(jsonxml)["cfdi:Comprobante"]["cfdi:Complemento"]["nomina12:Nomina"]["nomina12:Receptor"].FechaInicioRelLaboral);
          var fechaHoy = new Date();
          var antiguedad = fechaHoy.getTime() - fechaInicioRelacion.getTime();
          var antiguedadY = (antiguedad/(1000*60*60*24))/365;
          this.regUsuario.datosLaborales.antiguedad = Math.trunc(antiguedadY).toString();
          this.datempFormGroup.get('antigu').disable();
         }catch(error){
          console.log(error);
         }

         try{
          this.regUsuario.datosPersonales.nombre = '';
          var arrNombre = JSON.parse(jsonxml)["cfdi:Comprobante"]["cfdi:Receptor"].Nombre.split(' ');
          // console.log(this.removeAccents(this.usuario.name), this.removeAccents(arrNombre.reverse().toString().replaceAll(',', ' ')))
          this.regUsuario.datosPersonales.apellidop = arrNombre[0];
          this.regUsuario.datosPersonales.apellidom = arrNombre[1];
          this.regUsuario.datosPersonales.nombre = '';
          for(var i = 2; i<arrNombre.length; i++){
            this.regUsuario.datosPersonales.nombre += arrNombre[i] + ' ';
          }
          this.regUsuario.datosPersonales.nombre = this.regUsuario.datosPersonales.nombre.slice(0, this.regUsuario.datosPersonales.nombre.length - 1);
          this.regUsuario.egrydatban.extra1 = this.regUsuario.datosPersonales.nombre[0] + this.regUsuario.datosPersonales.apellidop + this.regUsuario.datosPersonales.apellidom[0];
          
          Swal.fire({
            title: 'Atención',
            html: `Verifique que su nombre es correcto. <br>Apellido paterno:  ${this.regUsuario.datosPersonales.apellidop} <br>Apellido materno: ${this.regUsuario.datosPersonales.apellidom} <br>Nombre(s):  ${this.regUsuario.datosPersonales.nombre}`,
            // text: "Verifique que su nombre es correcto. Apellido paterno: " +  + this.regUsuario.datosPersonales.apellidop  + 'Apellido materno: ' + this.regUsuario.datosPersonales.apellidop + 'Nombre(s): '+ this.regUsuario.datosPersonales.nombre,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, es correcto!',
            cancelButtonText: 'Incorrecto',
            allowOutsideClick: false
          }).then((result) => {
            if (!result.isConfirmed) {
              var arrNombre = JSON.parse(jsonxml)["cfdi:Comprobante"]["cfdi:Receptor"].Nombre.split(' ').reverse();
              // console.log(this.removeAccents(this.usuario.name), this.removeAccents(arrNombre.reverse().toString().replaceAll(',', ' ')))
              this.regUsuario.datosPersonales.apellidop = arrNombre[1];
              this.regUsuario.datosPersonales.apellidom = arrNombre[0];
              this.regUsuario.datosPersonales.nombre = '';
              for(var i = 2; i<arrNombre.length; i++){
                this.regUsuario.datosPersonales.nombre =  arrNombre[i] + ' ' + this.regUsuario.datosPersonales.nombre;
              }
              this.regUsuario.datosPersonales.nombre = this.regUsuario.datosPersonales.nombre.slice(0, this.regUsuario.datosPersonales.nombre.length - 1);
              this.regUsuario.egrydatban.extra1 = this.regUsuario.datosPersonales.nombre[0] + this.regUsuario.datosPersonales.apellidop + this.regUsuario.datosPersonales.apellidom[0];
            }
          })
        }catch(error){
          console.log(error);
         }

         try{
          var rfc = JSON.parse(jsonxml)["cfdi:Comprobante"]["cfdi:Emisor"].Rfc;
          this.aws.getCompanyByRfc(rfc).subscribe(resp => {
           if(resp.status){
            if(resp.contributor[0].contributor_type == 'PM'){
              var legal_entity = resp.legal_entity[0];
              // this.regUsuario.datosEmpleoAct.companyid = legal_entity.id;
              this.regUsuario.datosEmpleoAct.rfcemp = legal_entity.rfc;
              this.regUsuario.datosEmpleoAct.empresadat = legal_entity.business_name;
            }else if(resp.contributor[0].contributor_type == 'PF'){
              var person = resp.person[0];
              // this.regUsuario.datosEmpleoAct.companyid = person.id;
              this.regUsuario.datosEmpleoAct.rfcemp = person.rfc;
              this.regUsuario.datosEmpleoAct.empresadat = person.last_name + ' ' + person.second_last_name + ' ' + person.first_name;
            }
            try{
              this.regUsuario.datosEmpleoAct.companyid = resp.company[0].id;
            }catch{
              console.log('no tiene company');
            }
            if(rfc != 'CDE1210163W1'){
              this.regUsuario.datosEmpleoAct.arrdata = this.regUsuario.datosEmpleoAct.empresadat;
            }
            this.empresa = true;
            // this.datempFormGroup.get('empress').disable();
           }else{
            swal2.fire('Atención', 'El empleado no pertenece a una empresa registrada, comuniquese con el Administrador', 'info');
            this.router.navigate(['/home']).then(() => {
              this.usuarioService.logout();
              window.location.reload();

            //swal2.close();
            });
           }
          })
         }catch(error){
          console.log(error);
         }


         this.archivoNombres.XML = nombre;
      }else{
        swal2.fire('Atención', 'El comprobante debe tener una antigüedad máxima de 1 mes', 'info')
      }
      
    })
  }

  readFileXml(file: File): Observable<string> {
    const sub = new Subject<any>();
    var reader = new FileReader();
 
    reader.onload = () => {
       var xmlData = (reader.result);
       var parser = new DOMParser();
       var doc = parser.parseFromString(xmlData.toString().replace('C<', 'u'), "text/xml");
      // var doc = parser.parseFromString(xmlData.toString(), "text/xml");
       sub.next(doc);
       sub.complete();
    };
 
    reader.readAsText(file);
    return sub.asObservable();
 }

  async uploadToBucket() {
   swal2.fire('Subiendo archivos','','info');
   swal2.showLoading();
   if (this.myForm.value.fileIne === null) {
      swal2.fire('Falta archivo INE','','info');
   } else if (this.myForm.value.fileCompDom === null) {
      swal2.fire('Falta archivo comprobante de domicilio','','info');
   } else if (this.myForm.value.fileCurp === null) {
      swal2.fire('Falta archivo CURP','','info');
   } else if (this.myForm.value.fileEcuenta === null) {
      swal2.fire('Falta archivo Estado de cuenta','','info');
  } else if (this.myForm.value.fileXml === null) {
     swal2.fire('Falta archivo XML','','info');
  } else if (this.myForm.value.fileCsf === null) {
     swal2.fire('Falta archivo Comprobante de situación fiscal','','info');
   } else if (this.myForm.value.filesIngresos === null || (this.regUsuario.datosLaborales.formaPago == 'Semanal' && this.myForm.value.filesIngresos.length < 4) || (this.regUsuario.datosLaborales.formaPago == 'Quincenal' && this.myForm.value.filesIngresos.length < 2)) {
      swal2.fire('Faltan archivos comprobantes de ingresos','','info');
   } else {
     
     let resul = {
        /* fileCompDom: Object(),
        afileCurp: Object(),
        fileIne: Object(),
        fileCsf: Object(),
        fileXml: Object(),
        fileEcuenta: Object(),
        filesIngresos: [] */
     }
     var errores = [];
     var fileCompDom =     await this.callupload(this.myForm.value.fileCompDom, this.idu)
     fileCompDom.status ? resul['fileCompDom'] = fileCompDom : errores.push('Comprobante de domicilio');
     var afileCurp =       await this.callupload(this.myForm.value.fileCurp, this.idu);
     afileCurp.status ? resul['afileCurp'] = afileCurp : errores.push('CURP');
     var fileIne =         await this.callupload(this.myForm.value.fileIne, this.idu);
     fileIne.status ? resul['fileIne'] = fileIne : errores.push('INE');
     var fileCsf =         await this.callupload(this.myForm.value.fileCsf, this.idu);
     fileCsf.status ? resul['fileCsf'] = fileCsf : errores.push('Constancia situacion fiscal');
     var fileXml =         await this.callupload(this.myForm.value.fileXml, this.idu);
     fileXml.status ? resul['fileXml'] = fileXml : errores.push('XML');
     var fileEcuenta =     await this.callupload(this.myForm.value.fileEcuenta, this.idu);
     fileEcuenta.status ? resul['fileEcuenta'] = fileEcuenta : errores.push('Estado de cuenta');
     var filesIngresos =   await this.calluploadmulti(this.myForm.value.filesIngresos, this.idu);
     filesIngresos.filter(f => f.status == false).length == 0 ? resul['filesIngresos'] = filesIngresos : errores.push('Comprobantes de ingresos');
     if(errores.length > 0){
      var mensaje = '';
      errores.forEach(e => {
        mensaje += e + '<br>'
      })
      swal2.fire('Falló la carga de los archivos:', `${mensaje}`, 'info').then(result => {
        if(result.value){
          window.location.reload();
        }
      })
      return
     }
     localStorage.setItem('Documents', JSON.stringify(resul));
        this.exppanel = true;
        this.disablepanel = false;
        swal2.fire(`Archivos preparados para subir, por favor complete la información`,'','info')
   }
    
    
  }

  seleccionarEmpresa(){
    let company = this.companyes.find(c => c.business_name == this.regUsuario.datosEmpleoAct.arrdata);
    this.regUsuario.datosEmpleoAct.rfcemp = company.rfc;
    this.regUsuario.datosEmpleoAct.companyid = company.id;
  }
    
  async callupload(file, idu) {
    try {
      const r = await this.aws.uploadFile(file, idu)
      let resp = {
        status: true,
        resp: r,
        msg: 'Archivo subido correctamente'
      }
      return resp
    } catch (error) {
      let resp = {
        status: false,
        resp: error,
        msg: 'Fallo al subir archivo'
      }
      return resp
    }
  }

  async calluploadmulti(files, idu) {
  try {
    const r = await this.aws.uploadFiless(files, idu)
    return r
  } catch (error) {
    
  }
  }

  getDatafromxml() {
    var file = this.myForm.value.fileXml;
    var reader = new FileReader();
    reader.readAsText(file);
    
    reader.onloadend = function(){
      
        var xmlData = (reader.result);
        var parser = new DOMParser();
        var doc = parser.parseFromString(xmlData.toString(), "text/xml");
        return doc;
    };
  }

  getObjects(obj, key, val) {
    var objects = [];
    for (var i in obj) {
        if (!obj.hasOwnProperty(i)) continue;
        if (typeof obj[i] == 'object') {
            objects = objects.concat(this.getObjects(obj[i], key, val));    
        } else 
        //if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
        //if (i == key && obj[i] == val || i == key && val == '') { //
         if (i == key && obj[i].match(val) || i == key && val == '') {
            objects.push(obj);
       //} else if (obj[i] == val && key == ''){
      } else if (obj[i].match(val) && key == ''){
            //only add if the object is not already in the array
            if (objects.lastIndexOf(obj) == -1){
                objects.push(obj);
            }
        }
    }
    return objects;
}

//return an array of values that match on a certain key
 getValues(obj, key) {
    var objects = [];
    for (var i in obj) {
        if (!obj.hasOwnProperty(i)) continue;
        if (typeof obj[i] == 'object') {
            objects = objects.concat(this.getValues(obj[i], key));
        } else if (i == key) {
            objects.push(obj[i]);
        }
    }
    return objects;
}

//return an array of keys that match on a certain value
 getKeys(obj, val) {
    var objects = [];
    for (var i in obj) {
        if (!obj.hasOwnProperty(i)) continue;
        if (typeof obj[i] == 'object') {
            objects = objects.concat(this.getKeys(obj[i], val));
        } else if (obj[i] == val) {
            objects.push(i);
        }
    }
    return objects;
}




 xml2json(xml, tab) {
  var X = {
     toObj: function(xml) {
        var o = {};
        if (xml.nodeType==1) {   // element node ..
           if (xml.attributes.length)   // element with attributes  ..
              for (var i=0; i<xml.attributes.length; i++)
                 //o["@"+xml.attributes[i].nodeName] = (xml.attributes[i].nodeValue||"").toString();
                 o[xml.attributes[i].nodeName] = (xml.attributes[i].nodeValue||"").toString();
           if (xml.firstChild) { // element has child nodes ..
              var textChild=0, cdataChild=0, hasElementChild=false;
              for (var n=xml.firstChild; n; n=n.nextSibling) {
                 if (n.nodeType==1) hasElementChild = true;
                 else if (n.nodeType==3 && n.nodeValue.match(/[^ \f\n\r\t\v]/)) textChild++; // non-whitespace text
                 else if (n.nodeType==4) cdataChild++; // cdata section node
              }
              if (hasElementChild) {
                 if (textChild < 2 && cdataChild < 2) { // structured element with evtl. a single text or/and cdata node ..
                    X.removeWhite(xml);
                    for (var n=xml.firstChild; n; n=n.nextSibling) {
                       if (n.nodeType == 3)  // text node
                          o["#text"] = X.escape(n.nodeValue);
                       else if (n.nodeType == 4)  // cdata node
                          o["#cdata"] = X.escape(n.nodeValue);
                       else if (o[n.nodeName]) {  // multiple occurence of element ..
                          if (o[n.nodeName] instanceof Array)
                             o[n.nodeName][o[n.nodeName].length] = X.toObj(n);
                          else
                             o[n.nodeName] = [o[n.nodeName], X.toObj(n)];
                       }
                       else  // first occurence of element..
                          o[n.nodeName] = X.toObj(n);
                    }
                 }
                 else { // mixed content
                    if (!xml.attributes.length)
                       o = X.escape(X.innerXml(xml));
                    else
                       o["#text"] = X.escape(X.innerXml(xml));
                 }
              }
              else if (textChild) { // pure text
                 if (!xml.attributes.length)
                    o = X.escape(X.innerXml(xml));
                 else
                    o["#text"] = X.escape(X.innerXml(xml));
              }
              else if (cdataChild) { // cdata
                 if (cdataChild > 1)
                    o = X.escape(X.innerXml(xml));
                 else
                    for (var n=xml.firstChild; n; n=n.nextSibling)
                       o["#cdata"] = X.escape(n.nodeValue);
              }
           }
           if (!xml.attributes.length && !xml.firstChild) o = null;
        }
        else if (xml.nodeType==9) { // document.node
           o = X.toObj(xml.documentElement);
        }
        else
           alert("unhandled node type: " + xml.nodeType);
        return o;
     },
     toJson: function(o, name, ind) {
        var json = name ? ("\""+name+"\"") : "";
        if (o instanceof Array) {
           for (var i=0,n=o.length; i<n; i++)
              o[i] = X.toJson(o[i], "", ind+"\t");
           json += (name?":[":"[") + (o.length > 1 ? ("\n"+ind+"\t"+o.join(",\n"+ind+"\t")+"\n"+ind) : o.join("")) + "]";
        }
        else if (o == null)
           json += (name&&":") + "null";
        else if (typeof(o) == "object") {
           var arr = [];
           for (var m in o)
              arr[arr.length] = X.toJson(o[m], m, ind+"\t");
           json += (name?":{":"{") + (arr.length > 1 ? ("\n"+ind+"\t"+arr.join(",\n"+ind+"\t")+"\n"+ind) : arr.join("")) + "}";
        }
        else if (typeof(o) == "string")
           json += (name&&":") + "\"" + o.toString() + "\"";
        else
           json += (name&&":") + o.toString();
        return json;
     },
     innerXml: function(node) {
        var s = ""
        if ("innerHTML" in node)
           s = node.innerHTML;
        else {
           var asXml = function(n) {
              var s = "";
              if (n.nodeType == 1) {
                 s += "<" + n.nodeName;
                 for (var i=0; i<n.attributes.length;i++)
                    s += " " + n.attributes[i].nodeName + "=\"" + (n.attributes[i].nodeValue||"").toString() + "\"";
                 if (n.firstChild) {
                    s += ">";
                    for (var c=n.firstChild; c; c=c.nextSibling)
                       s += asXml(c);
                    s += "</"+n.nodeName+">";
                 }
                 else
                    s += "/>";
              }
              else if (n.nodeType == 3)
                 s += n.nodeValue;
              else if (n.nodeType == 4)
                 s += "<![CDATA[" + n.nodeValue + "]]>";
              return s;
           };
           for (var c=node.firstChild; c; c=c.nextSibling)
              s += asXml(c);
        }
        return s;
     },
     escape: function(txt) {
        return txt.replace(/[\\]/g, "\\\\")
                  .replace(/[\"]/g, '\\"')
                  .replace(/[\n]/g, '\\n')
                  .replace(/[\r]/g, '\\r');
     },
     removeWhite: function(e) {
        e.normalize();
        for (var n = e.firstChild; n; ) {
           if (n.nodeType == 3) {  // text node
              if (!n.nodeValue.match(/[^ \f\n\r\t\v]/)) { // pure whitespace text node
                 var nxt = n.nextSibling;
                 e.removeChild(n);
                 n = nxt;
              }
              else
                 n = n.nextSibling;
           }
           else if (n.nodeType == 1) {  // element node
              X.removeWhite(n);
              n = n.nextSibling;
           }
           else                      // any other node
              n = n.nextSibling;
        }
        return e;
     }
  };
  if (xml.nodeType == 9) // document node
     xml = xml.documentElement;
  var json = X.toJson(X.toObj(X.removeWhite(xml)), xml.nodeName, "\t");
  return "{\n" + tab + (tab ? json.replace(/\t/g, tab) : json.replace(/\t|\n/g, "")) + "\n}";
}
validarTerminos(){
   this.showModalTerminosYCondiciones = false;
}

identificacionChange(){
  console.log(this.regUsuario.datosPersonales.identificacion);
  switch(this.regUsuario.datosPersonales.identificacion){
    case 'Pasaporte':
      this.imagen = '../../../assets/img/pasaporte.jpg';
      break;
    case 'INE':
      this.imagen = '../../../assets/img/ine.png';
      break;
    case 'Cedula profesional':
      this.imagen = '../../../assets/img/cedula.jpg';
      break;
    case 'Cartilla militar':
      this.imagen = '../../../assets/img/cartilla.jpg';
      break;
    default:
      break;
  }
}

selPais(){
  var pais = this.countries.find(c => c.id == this.regUsuario.datosDomicilio.paisdom);
  this.regUsuario.datosDomicilio.paisdomlet = pais.name;
}

}
